<template>
  <v-row class="d-flex justify-center pt-5"
    ><v-col cols="12" xl="8" md="11" xs="12">
      <v-card elevation="4">
        <v-form v-model="valid" ref="form">
          <v-container class="pa-6">
            <v-row d-flex justify="center" style="text-align: center"
              ><h2>
                {{ course }} <br />
                {{ date }}
              </h2>
            </v-row>
            <v-row class="d-flex justify-center"
              ><img
                style="max-width: 80%"
                :src="require('@/assets/DataCore.svg')"
            /></v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  :value="date"
                  label="Date"
                  readonly
                  prepend-icon="mdi-calendar"></v-text-field
              ></v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  :value="duration"
                  label="Duration"
                  readonly
                  prepend-icon="mdi-clock-outline"></v-text-field
              ></v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  :value="location"
                  label="Location"
                  readonly
                  prepend-icon="mdi-map"></v-text-field
              ></v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  :value="language"
                  label="Language"
                  readonly
                  prepend-icon="mdi-earth"></v-text-field
              ></v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Day</th>
                        <th>Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in schedule" :key="item.day">
                        <td>{{ item.day }}</td>
                        <td>{{ item.time }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table></v-col
              >
            </v-row>
            <v-row>
              <v-col cols="12"> <div v-html="markdownToHtml"></div></v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="firstname"
                  :rules="nameRules"
                  label="First name"
                  required></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  v-model="lastname"
                  :rules="nameRules"
                  label="Last name"
                  required></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="E-mail"
                  required></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="company"
                  :rules="companyRules"
                  label="Company name"
                  required></v-text-field>
              </v-col>
              <v-col md="3" cols="12">
                <v-text-field
                  required
                  v-model="address_street"
                  :rules="address_streetRules"
                  label="Address"
                  required></v-text-field
              ></v-col>
              <v-col md="3" cols="12">
                <v-text-field
                  required
                  @keydown.space.prevent
                  :rules="address_zipRules"
                  v-model.trim="address_zip"
                  label="Zip Code / Postal Code"
                  required></v-text-field
              ></v-col>
              <v-col md="3" cols="12">
                <v-text-field
                  required
                  :rules="address_cityRules"
                  v-model="address_city"
                  label="City"
                  required></v-text-field
              ></v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  type="number"
                  required
                  :rules="attendeesRules"
                  v-model="attendees"
                  min="1"
                  max="7"
                  label="Number of attendees"
                  required></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <v-textarea
                  v-model="questions"
                  label="Additional comments (optional)"></v-textarea>
              </v-col>
            </v-row>
            <v-row d-flex justify="center">
              <p class="font-size text-caption text-center">
                Our events are realized in cooperation with the respective
                manufacturers.
              </p>
              <p class="font-size text-caption text-center">
                If you participate in one of our events or register for one of
                our events, you agree with the passing on of your contact data
                to the manufacturer.
              </p></v-row
            >
            <v-row d-flex justify="center">
              <v-btn
                class="mb-4"
                :color="button_color"
                @click="validate"
                :loading="loading">
                <v-icon v-if="button == 'check'" color="white">
                  mdi-check
                </v-icon>
                <v-icon v-if="button == 'close'" color="white">
                  mdi-close
                </v-icon>
                <span v-else>
                  {{ button }}
                </span>
              </v-btn>
            </v-row>
          </v-container>
        </v-form></v-card
      ></v-col
    ></v-row
  >
</template>

<script>
import axios from "axios"
import { marked } from "marked"

export default {
  name: "Training-Component",

  data: () => ({
    loading: false,
    button: "Book",
    button_color: "primary",
    markdown: "",
    company: "",
    attendees: "",
    address_street: "",
    address_zip: "",
    address_city: "",
    questions: "",
    firstname: "",
    lastname: "",
    email: "",
    valid: false,
    address_zipRules: [
      (v) => !!v || "Zip Code is required",
      (v) => v.trim().length == 5 || "Zip Code is entered incorrectly",
      (v) =>
        new RegExp(/^(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})$/).test(v) ||
        "Zip Code is entered incorrectly"
    ],
    address_cityRules: [
      (v) => !!v || "City is required",
      (v) =>
        !new RegExp(/\d/g).test(v) || "There can't be numbers in a city name"
    ],
    address_streetRules: [
      (v) => !!v || "Address is required",
      (v) => new RegExp(/\d/g).test(v) || "Address needs a house number"
    ],
    nameRules: [(v) => !!v || "Name is required"],
    companyRules: [(v) => !!v || "Company is required"],
    attendeesRules: [
      (v) => !!v || "Number of attendees is required",
      (v) => v <= 7 || "Number of attendees can be max. of 7",
      (v) => v >= 1 || "Number of attendees must be at least 1"
    ],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid"
    ]
  }),
  props: {
    course: String,
    date: String,
    location: String,
    duration: String,
    description: String,
    schedule: Array,
    icon: String,
    language: String
  },
  computed: {
    markdownToHtml() {
      return marked(this.description)
    }
  },
  methods: {
    changeButton(color, label, time) {
      var label_button = this.button
      var color_button = this.button_color
      this.button = label
      this.button_color = color
      setTimeout(() => {
        this.button = label_button
        this.button_color = color_button
      }, time)
    },
    validate(e) {
      this.loading = true
      this.$refs.form.validate()
      if (this.valid) {
        var payload = {
          company: this.company,
          attendees: this.attendees,
          questions: this.questions,
          firstname: this.firstname,
          lastname: this.lastname,
          address: this.address_street,
          address_zip: this.address_zip,
          address_city: this.address_city,
          email: this.email
        }
        axios
          .post("sendMail", payload)
          .then((res) => {
            this.loading = false
            this.changeButton("green lighten-1", "check", "6000")
            this.$root.$emit("openAlert", {
              type: "success",
              message:
                "Thank you for your request. Our sales team will get in touch with you."
            })
            this.$refs.form.reset()
          })
          .catch((err) => {
            this.loading = false
            this.changeButton("red lighten-1", "close", "6000")
            console.log(err)
            this.$root.$emit("openAlert", {
              type: "error",
              message: "Something went wrong."
            })
          })
        e.preventDefault()
      } else {
        e.preventDefault()
        this.loading = false
      }
    }
  }
}
</script>

<style></style>
