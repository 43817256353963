import Vue from "vue"
import Vuetify from "vuetify/lib/framework"
import "@mdi/font/css/materialdesignicons.css"

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: "mdi"
  },
  theme: {
    themes: {
      light: {
        primary: "#e95149"
      }
    }
  }
})
