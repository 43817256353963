<template>
  <v-alert
    v-if="show"
    dismissible
    :type="type"
    :elevation="elevation"
    tile
    transition="slide-x-reverse-transition"
    class="onTop"
    min-width="22vw">
    {{ message }}
  </v-alert>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      type: "",
      message: ""
    }
  },
  created() {
    this.$root.$on("openAlert", (data) => this.showAlert(data))
  },
  methods: {
    showAlert: function (data) {
      console.log(data)
      this.type = data.type
      this.message = data.message
      this.show = true
      setTimeout(() => {
        this.show = false
      }, 10000)
    }
  },
  props: {
    elevation: { type: Number, default: 1 }
  }
}
</script>

<style lang="scss" scoped>
.onTop {
  position: fixed;
  right: 2vw;
  top: 3em;
  z-index: 999;
}
</style>
