<template>
  <v-app>
    <alert></alert>
    <v-main>
      <v-app-bar color="" elevation="4"
        ><img
          style="height: 90%; width: auto"
          :src="require('@/assets/Jiliti-StorTrec-Horizontal-Black.svg')"
      /></v-app-bar>
      <v-container>
        <!-- <div
          style="
            min-height: 80vh;
            display: flex;
            align-items: center;
            justify-content: center;
          ">
          <h1>
            Schulungstermine auf Anfrage
            <a href="mailto:sales@stortrec.de">sales@stortrec.de</a>
          </h1>
        </div> -->
        <training
          v-if="!trainingDate"
          :course="course1.course"
          :date="course1.date"
          :duration="course1.duration"
          :location="course1.location"
          :schedule="course1.schedule"
          :description="course1.description"
          :icon="course1.icon"
          :language="course1.language"></training>
        <training
          v-if="!trainingDate2"
          :course="course2.course"
          :date="course2.date"
          :duration="course2.duration"
          :location="course2.location"
          :schedule="course2.schedule"
          :description="course2.description"
          :icon="course2.icon"
          :language="course2.language"></training>
        <v-row v-else class="text-center pa-4">
          <v-col cols="12" class="pb-0">
            <v-icon color="secondary" size="50px">
              mdi-emoticon-sad-outline
            </v-icon>
          </v-col>
          <v-col cols="12" class="text--secondary pt-2">
            There are currently no trainings available.
          </v-col>
        </v-row>
      </v-container>
      <v-footer elevation="6" padless app>
        <v-row justify="center" no-gutters>
          <v-btn
            color="black"
            class="my-2"
            @click="
              dialog = !dialog
              dialog_text = 'privacy'
            "
            text
            >Datenschutzerklärung</v-btn
          >
          <v-btn
            color="black"
            class="my-2"
            @click="
              dialog = !dialog
              dialog_text = 'imprint'
            "
            text
            >Impressum</v-btn
          >
          <v-btn
            color="black"
            class="my-2"
            @click="openInNewTab('tel:+49602897960')"
            text>
            <v-icon>mdi-phone-outline</v-icon><span>(+49)6028 97960</span>
          </v-btn>
        </v-row>
      </v-footer>

      <div class="text-center">
        <v-dialog v-model="dialog">
          <v-card>
            <v-card-title
              class="text-h5 grey lighten-2 d-flex justify-lg-space-between">
              <div v-if="dialog_text == 'imprint'">Impressum</div>
              <div v-if="dialog_text == 'privacy'">Datenschutzerklärung</div>
              <v-btn @click="dialog = !dialog"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </v-card-title>
            <v-card-text>
              <div
                class="my-2"
                v-if="dialog_text == 'imprint'"
                v-html="imprint_text"></div>
              <div
                class="my-2"
                v-if="dialog_text == 'privacy'"
                v-html="privacy_text"></div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </v-main>
  </v-app>
</template>

<script>
const dayjs = require("dayjs")
const customParseFormat = require("dayjs/plugin/customParseFormat")
dayjs.extend(customParseFormat)
import Training from "@/components/Training.vue"
import Alert from "@/globalComponents/Alerts.vue"

export default {
  name: "App",

  components: {
    Training,
    Alert
  },
  mounted() {
    const date = dayjs(this.course1.date.slice(12), "DD.MM.YYYY")
    const date2 = dayjs(this.course2.date.slice(12), "DD.MM.YYYY")
    this.trainingDate = dayjs().isAfter(date)
    this.trainingDate2 = dayjs().isAfter(date2)
  },
  methods: {
    openInNewTab(url) {
      window.open(url, "_blank").focus()
    }
  },
  data: () => ({
    dialog: false,
    trainingDate: true,
    trainingDate2: true,
    dialog_text: "",
    imprint_text: `<div id="footer_imprint_textblock">      <p>
    <b>Anschrift</b>
    <br>
    <br> StorTrec AG
    <br> Nordring 53-55
    <br> 63843 Niedernberg </p>
    <p><br>
    <b>Vertreten durch</b>
    <br>
    <br>
    Aufsichtsratvorsitzender
    <br>
    Georg Kunze
    <br>
    <br> Mitglieder des Vorstandes
    <br> Martin Hagmann, Ronald May </p>
    <p>
    <br>
    <b>Kontakt:</b>
    <br>
    <br> Telefon: +49 (0) 60 28 97 96 - 0
    <br> Telefax: +49 (0) 60 28 97 96 - 299
    <br> E-Mail: webmaster@stortrec.com </p>
    <p>
    <br>
    <b>Registereintrag:</b>
    <br>
    <br> Eintragung im Handelsregister.
    <br> Registergericht: Aschaffenburg
    <br> Registernummer: HRB 9127 </p>
    <p>
    <br>
    <b>Umsatzsteuer-ID:</b>
    <br>
    <br> Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:
    <br> DE 216240064
    <br>
    </p></div>`,
    privacy_text: `<div id="footer_privacy_policy_textblock">
  <p>Personenbezogene Daten (nachfolgend zumeist nur „Daten“ genannt) werden von uns nur im Rahmen der Erforderlichkeit sowie zum Zwecke der Bereitstellung eines funktionsfähigen und nutzerfreundlichen Internetauftritts, inklusive seiner Inhalte und der dort angebotenen Leistungen, verarbeitet.</p>
  <p>Gemäß Art. 4 Ziffer 1. der Verordnung (EU) 2016/679, also der Datenschutz-Grundverordnung (nachfolgend nur „DSGVO“ genannt), gilt als „Verarbeitung“ jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführter Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten, wie das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.</p>
  <p>Mit der nachfolgenden Datenschutzerklärung informieren wir Sie insbesondere über Art, Umfang, Zweck, Dauer und Rechtsgrundlage der Verarbeitung personenbezogener Daten, soweit wir entweder allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung entscheiden. Zudem informieren wir Sie nachfolgend über die von uns zu Optimierungszwecken sowie zur Steigerung der Nutzungsqualität eingesetzten Fremdkomponenten, soweit hierdurch Dritte Daten in wiederum eigener Verantwortung verarbeiten.</p>
  <p>Unsere Datenschutzerklärung ist wie folgt gegliedert:</p>
  <p>I. Informationen über uns als Verantwortliche<br>II. Rechte der Nutzer und Betroffenen<br>III. Informationen zur Datenverarbeitung</p>
  <h3>I. Informationen über uns als Verantwortliche</h3>
  <p>Verantwortlicher Anbieter dieses Internetauftritts im datenschutzrechtlichen Sinne ist:</p>
  <p><span>StorTrec AG<br>Nordring 53-55<br>63843 Niedernberg<br>Deutschland</span></p>
<p><span>Vertreten durch die Mitglieder des Vorstandes: Martin Hagmann, Ronald May</span></p>
  <p><span>Telefon: +49 (0) 60 28 97 96-0<br>Telefax: +49 (0) 60 28 97 96 - 299<br>E-Mail: contact@stortrec.de</span></p>
  <p>Datenschutzbeauftragte/r beim Anbieter ist:</p>
  <p><span>Klaus Götz</span></p>
  <p><span>Telefon: +49 60 28 97 96 - 381<br>E-Mail: dsb-stortrec@stortrec.com</span></p>
  <h3>II. Rechte der Nutzer und Betroffenen</h3>
  <p>Mit Blick auf die nachfolgend noch näher beschriebene Datenverarbeitung haben die Nutzer und Betroffenen das Recht</p>
  <ul>
      <li>auf Bestätigung, ob sie betreffende Daten verarbeitet werden, auf Auskunft über die verarbeiteten Daten, auf weitere Informationen über die Datenverarbeitung sowie auf Kopien der Daten (vgl. auch Art. 15 DSGVO);</li>
      <li>auf Berichtigung oder Vervollständigung unrichtiger bzw. unvollständiger Daten (vgl. auch Art. 16 DSGVO);</li>
      <li>auf unverzügliche Löschung der sie betreffenden Daten (vgl. auch Art. 17 DSGVO), oder, alternativ, soweit eine weitere Verarbeitung gemäß Art. 17 Abs. 3 DSGVO erforderlich ist, auf Einschränkung der Verarbeitung nach Maßgabe von Art. 18 DSGVO;</li>
      <li>auf Erhalt der sie betreffenden und von ihnen bereitgestellten Daten und auf Übermittlung dieser Daten an andere Anbieter/Verantwortliche (vgl. auch Art. 20 DSGVO);</li>
      <li>auf Beschwerde gegenüber der Aufsichtsbehörde, sofern sie der Ansicht sind, dass die sie betreffenden Daten durch den Anbieter unter Verstoß gegen datenschutzrechtliche Bestimmungen verarbeitet werden (vgl. auch Art. 77 DSGVO).</li>
  </ul>
  <p>Darüber hinaus ist der Anbieter dazu verpflichtet, alle Empfänger, denen gegenüber Daten durch den Anbieter offengelegt worden sind, über jedwede Berichtigung oder Löschung von Daten oder die Einschränkung der Verarbeitung, die aufgrund der Artikel 16, 17 Abs. 1, 18 DSGVO erfolgt, zu unterrichten. Diese Verpflichtung besteht jedoch nicht, soweit diese Mitteilung unmöglich oder mit einem unverhältnismäßigen Aufwand verbunden ist. Unbeschadet dessen hat der Nutzer ein Recht auf Auskunft über diese Empfänger.</p>
  <p><strong>Ebenfalls haben die Nutzer und Betroffenen nach Art. 21 DSGVO das Recht auf Widerspruch gegen die künftige Verarbeitung der sie betreffenden Daten, sofern die Daten durch den Anbieter nach Maßgabe von Art. 6 Abs. 1 lit. f) DSGVO verarbeitet werden. Insbesondere ist ein Widerspruch gegen die Datenverarbeitung zum Zwecke der Direktwerbung statthaft.</strong></p>
  <h3>III. Informationen zur Datenverarbeitung</h3>
  <p>Ihre bei Nutzung unseres Internetauftritts verarbeiteten Daten werden gelöscht oder gesperrt, sobald der Zweck der Speicherung entfällt, der Löschung der Daten keine gesetzlichen Aufbewahrungspflichten entgegenstehen und nachfolgend keine anderslautenden Angaben zu einzelnen Verarbeitungsverfahren gemacht werden.</p>

  <h4>Serverdaten</h4>
  <p>Aus technischen Gründen, insbesondere zur Gewährleistung eines sicheren und stabilen Internetauftritts, werden Daten durch Ihren Internet-Browser an uns bzw. an unseren Webspace-Provider übermittelt. Mit diesen sog. Server-Logfiles werden u.a. Typ und Version Ihres Internetbrowsers, das Betriebssystem, die Website, von der aus Sie auf unseren Internetauftritt gewechselt haben (Referrer URL), die Website(s) unseres Internetauftritts, die Sie besuchen, Datum und Uhrzeit des jeweiligen Zugriffs sowie die IP-Adresse des Internetanschlusses, von dem aus die Nutzung unseres Internetauftritts erfolgt, erhoben.</p>
  <p>Diese so erhobenen Daten werden vorrübergehend gespeichert, dies jedoch nicht gemeinsam mit anderen Daten von Ihnen.</p>
  <p>Diese Speicherung erfolgt auf der Rechtsgrundlage von Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Verbesserung, Stabilität, Funktionalität und Sicherheit unseres Internetauftritts.</p>
  <p>Die Daten werden spätestens nach sieben Tage wieder gelöscht, soweit keine weitere Aufbewahrung zu Beweiszwecken erforderlich ist. Andernfalls sind die Daten bis zur endgültigen Klärung eines Vorfalls ganz oder teilweise von der Löschung ausgenommen.</p>

  <h4>Cookies</h4>
  <h5>a) Sitzungs-Cookies/Session-Cookies</h5>
  <p>Wir verwenden mit unserem Internetauftritt sog. Cookies. Cookies sind kleine Textdateien oder andere Speichertechnologien, die durch den von Ihnen eingesetzten Internet-Browser auf Ihrem Endgerät ablegt und gespeichert werden. Durch diese Cookies werden im individuellen Umfang bestimmte Informationen von Ihnen, wie beispielsweise Ihre Browser- oder Standortdaten oder Ihre IP-Adresse, verarbeitet. &nbsp;</p>
  <p>Durch diese Verarbeitung wird unser Internetauftritt benutzerfreundlicher, effektiver und sicherer, da die Verarbeitung bspw. die Wiedergabe unseres Internetauftritts in unterschiedlichen Sprachen oder das Angebot einer Warenkorbfunktion ermöglicht.</p>
  <p>Rechtsgrundlage dieser Verarbeitung ist Art. 6 Abs. 1 lit b.) DSGVO, sofern diese Cookies Daten zur Vertragsanbahnung oder Vertragsabwicklung verarbeitet werden.</p>
  <p>Falls die Verarbeitung nicht der Vertragsanbahnung oder Vertragsabwicklung dient, liegt unser berechtigtes Interesse in der Verbesserung der Funktionalität unseres Internetauftritts. Rechtsgrundlage ist in dann Art. 6 Abs. 1 lit. f) DSGVO.</p>
  <p>Mit Schließen Ihres Internet-Browsers werden diese Session-Cookies gelöscht.</p>
  <h5>b) Drittanbieter-Cookies</h5>
  <p>Gegebenenfalls werden mit unserem Internetauftritt auch Cookies von Partnerunternehmen, mit denen wir zum Zwecke der Werbung, der Analyse oder der Funktionalitäten unseres Internetauftritts zusammenarbeiten, verwendet.</p>
  <p>Die Einzelheiten hierzu, insbesondere zu den Zwecken und den Rechtsgrundlagen der Verarbeitung solcher Drittanbieter-Cookies, entnehmen Sie bitte den nachfolgenden Informationen.</p>
  <h5>c) Beseitigungsmöglichkeit</h5>
  <p>Sie können die Installation der Cookies durch eine Einstellung Ihres Internet-Browsers verhindern oder einschränken. Ebenfalls können Sie bereits gespeicherte Cookies jederzeit löschen. Die hierfür erforderlichen Schritte und Maßnahmen hängen jedoch von Ihrem konkret genutzten Internet-Browser ab. Bei Fragen benutzen Sie daher bitte die Hilfefunktion oder Dokumentation Ihres Internet-Browsers oder wenden sich an dessen Hersteller bzw. Support. Bei sog. Flash-Cookies kann die Verarbeitung allerdings nicht über die Einstellungen des Browsers unterbunden werden. Stattdessen müssen Sie insoweit die Einstellung Ihres Flash-Players ändern. Auch die hierfür erforderlichen Schritte und Maßnahmen hängen von Ihrem konkret genutzten Flash-Player ab. Bei Fragen benutzen Sie daher bitte ebenso die Hilfefunktion oder Dokumentation Ihres Flash-Players oder wenden sich an den Hersteller bzw. Benutzer-Support.</p>
  <p>Sollten Sie die Installation der Cookies verhindern oder einschränken, kann dies allerdings dazu führen, dass nicht sämtliche Funktionen unseres Internetauftritts vollumfänglich nutzbar sind.</p>

  <h4>Vertragsabwicklung</h4>
  <p>Die von Ihnen zur Inanspruchnahme unseres Waren- und/oder Dienstleistungsangebots übermittelten Daten werden von uns zum Zwecke der Vertragsabwicklung verarbeitet und sind insoweit erforderlich. Vertragsschluss und Vertragsabwicklung sind ohne Bereitstellung Ihrer Daten nicht möglich.</p>
  <p>Rechtsgrundlage für die Verarbeitung ist Art. 6 Abs. 1 lit. b) DSGVO.</p>
  <p>Wir löschen die Daten mit vollständiger Vertragsabwicklung, müssen dabei aber die steuer- und handelsrechtlichen Aufbewahrungsfristen beachten.</p>
  <p>Im Rahmen der Vertragsabwicklung geben wir Ihre Daten an das mit der Warenlieferung beauftragte Transportunternehmen oder an den Finanzdienstleister weiter, soweit die Weitergabe zur Warenauslieferung oder zu Bezahlzwecken erforderlich ist.</p>
  <p>Rechtsgrundlage für die Weitergabe der Daten ist dann Art. 6 Abs. 1 lit. b) DSGVO.</p>

  <h4>Kundenkonto / Registrierungsfunktion</h4>
  <p>Falls Sie über unseren Internetauftritt ein Kundenkonto bei uns anlegen, werden wir die von Ihnen bei der Registrierung eingegebenen Daten (also bspw. Ihren Namen, Ihre Anschrift oder Ihre E-Mail-Adresse) ausschließlich für vorvertragliche Leistungen, für die Vertragserfüllung oder zum Zwecke der Kundenpflege (bspw. um Ihnen eine Übersicht über Ihre bisherigen Bestellungen bei uns zur Verfügung zu stellen oder um Ihnen die sog. Merkzettelfunktion anbieten zu können) erheben und speichern. Gleichzeitig speichern wir dann die IP-Adresse und das Datum Ihrer Registrierung nebst Uhrzeit. Eine Weitergabe dieser Daten an Dritte erfolgt natürlich nicht.</p>
  <p>Im Rahmen des weiteren Anmeldevorgangs wird Ihre Einwilligung in diese Verarbeitung eingeholt und auf diese Datenschutzerklärung verwiesen. Die dabei von uns erhobenen Daten werden ausschließlich für die Zurverfügungstellung des Kundenkontos verwendet.&nbsp;</p>
  <p>Soweit Sie in diese Verarbeitung einwilligen, ist Art. 6 Abs. 1 lit. a) DSGVO Rechtsgrundlage für die Verarbeitung.</p>
  <p>Sofern die Eröffnung des Kundenkontos zusätzlich auch vorvertraglichen Maßnahmen oder der Vertragserfüllung dient, so ist Rechtsgrundlage für diese Verarbeitung auch noch Art. 6 Abs. 1 lit. b) DSGVO.</p>
  <p>Die uns erteilte Einwilligung in die Eröffnung und den Unterhalt des Kundenkontos können Sie gemäß Art. 7 Abs. 3 DSGVO jederzeit mit Wirkung für die Zukunft widerrufen. Hierzu müssen Sie uns lediglich über Ihren Widerruf in Kenntnis setzen.</p>
  <p>Die insoweit erhobenen Daten werden gelöscht, sobald die Verarbeitung nicht mehr erforderlich ist. Hierbei müssen wir aber steuer- und handelsrechtliche Aufbewahrungsfristen beachten.</p>

  <h4>Kontaktanfragen / Kontaktmöglichkeit</h4>
  <p>Sofern Sie per Kontaktformular oder E-Mail mit uns in Kontakt treten, werden die dabei von Ihnen angegebenen Daten zur Bearbeitung Ihrer Anfrage genutzt. Die Angabe der Daten ist zur Bearbeitung und Beantwortung Ihre Anfrage erforderlich - ohne deren Bereitstellung können wir Ihre Anfrage nicht oder allenfalls eingeschränkt beantworten.</p>
  <p>Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 lit. b) DSGVO.</p>
  <p>Ihre Daten werden gelöscht, sofern Ihre Anfrage abschließend beantwortet worden ist und der Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen, wie bspw. bei einer sich etwaig anschließenden Vertragsabwicklung.</p>

  <h4>Online-Stellenbewerbungen / Veröffentlichung von Stellenanzeigen</h4>
  <p>Wir bieten Ihnen die Möglichkeit an, sich bei uns über unseren Internetauftritt bewerben zu können. Bei diesen digitalen Bewerbungen werden Ihre Bewerber- und Bewerbungsdaten von uns zur Abwicklung des Bewerbungsverfahrens elektronisch erhoben und verarbeitet.</p>
  <p>Rechtsgrundlage für diese Verarbeitung ist § 26 Abs. 1 S. 1 BDSG i.V.m. Art. 88 Abs. 1 DSGVO.</p>
  <p>Sofern nach dem Bewerbungsverfahren ein Arbeitsvertrag geschlossen wird, speichern wir Ihre bei der Bewerbung übermittelten Daten in Ihrer Personalakte zum Zwecke des üblichen Organisations- und Verwaltungsprozesses – dies natürlich unter Beachtung der weitergehenden rechtlichen Verpflichtungen.</p>
  <p>Rechtsgrundlage für diese Verarbeitung ist ebenfalls § 26 Abs. 1 S. 1 BDSG i.V.m. Art. 88 Abs. 1 DSGVO.</p>
  <p>Bei der Zurückweisung einer Bewerbung löschen wir die uns übermittelten Daten automatisch zwei Monate nach der Bekanntgabe der Zurückweisung. Die Löschung erfolgt jedoch nicht, wenn die Daten aufgrund gesetzlicher Bestimmungen, bspw. wegen der Beweispflichten nach dem AGG, eine längere Speicherung von bis zu vier Monaten oder bis zum Abschluss eines gerichtlichen Verfahrens erfordern.</p>
  <p>Rechtsgrundlage ist in diesem Fall Art. 6 Abs. 1 lit. f) DSGVO und § 24 Abs. 1 Nr. 2 BDSG. Unser berechtigtes Interesse liegt in der Rechtsverteidigung bzw. -durchsetzung.</p>
  <p>Sofern Sie ausdrücklich in eine längere Speicherung Ihrer Daten einwilligen, bspw. für Ihre Aufnahme in eine Bewerber- oder Interessentendatenbank, werden die Daten aufgrund Ihrer Einwilligung weiterverarbeitet. Rechtsgrundlage ist dann Art. 6 Abs. 1 lit. a) DSGVO. Ihre Einwilligung können Sie aber natürlich jederzeit nach Art. 7 Abs. 3 DSGVO durch Erklärung uns gegenüber mit Wirkung für die Zukunft widerrufen.</p>

  <h3>Verlinkung Social-Media über Grafik oder Textlink</h3>
  <p>Wir bewerben auf unserer Webseite auch Präsenzen auf den nachstehend aufgeführten sozialen Netzwerken. Die Einbindung erfolgt dabei über eine verlinkte Grafik des jeweiligen Netzwerks. Durch den Einsatz dieser verlinkten Grafik wird verhindert, dass es bei dem Aufruf einer Website, die über eine Social-Media-Bewerbung verfügt, automatisch zu einem Verbindungsaufbau zum jeweiligen Server des sozialen Netzwerks kommt, um eine Grafik des jeweiligen Netzwerkes selbst darzustellen. Erst durch einen Klick auf die entsprechende Grafik wird der Nutzer zu dem Dienst des jeweiligen sozialen Netzwerks weitergeleitet.</p>
  <p>Nach der Weiterleitung des Nutzers werden durch das jeweilige Netzwerk Informationen über den Nutzer erfasst. Es kann hierbei nicht ausgeschlossen werden, dass eine Verarbeitung der so erhobenen&nbsp; Daten in den USA stattfindet.</p>
  <p>Dies sind zunächst Daten wie IP-Adresse, Datum, Uhrzeit und besuchte Seite. Ist der Nutzer währenddessen in seinem Benutzerkonto des jeweiligen Netzwerks eingeloggt, kann der Netzwerk-Betreiber ggf. die gesammelten Informationen des konkreten Besuchs des Nutzers dem persönlichen Account des Nutzers zuordnen. Interagiert der Nutzer über einen „Teilen“-Button des jeweiligen Netzwerks, können diese Informationen in dem persönlichen Benutzerkonto des Nutzers gespeichert und ggf. veröffentlicht werden. Will der Nutzer verhindern, dass die gesammelten Informationen unmittelbar seinem Benutzerkonto zugeordnet werden, muss er sich vor dem Anklicken der Grafik ausloggen. Zudem besteht die Möglichkeit, das jeweilige Benutzerkonto entsprechend zu konfigurieren.</p>
  <p>Folgende soziale Netzwerke werden in unsere Seite durch Verlinkung eingebunden:</p>

  <h4>facebook</h4>
  <p>Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Ireland, ein Tochterunternehmen der Facebook Inc., 1601 S. California Ave., Palo Alto, CA 94304, USA.</p>
  <p>Datenschutzerklärung: <a href="https://www.facebook.com/policy.php" target="_blank" rel="noopener">https://www.facebook.com/policy.php</a></p>

  <h4>twitter</h4>
  <p>Twitter Inc., 795 Folsom St., Suite 600, San Francisco, CA 94107, USA</p>
  <p>Datenschutzerklärung: <a href="https://twitter.com/privacy" target="_blank" rel="noopener">https://twitter.com/privacy</a></p>

  <h4>LinkedIn</h4>
  <p>LinkedIn Ireland Unlimited Company, Wilton Plaza, Wilton Place, Dublin 2, Irland, ein Tochterunternehmen der LinkedIn Corporation, 1000 W. Maude Avenue, Sunnyvale, CA 94085 USA.</p>
  <p>Datenschutzerklärung: <a href="https://www.linkedin.com/legal/privacy-policy" target="_blank" rel="noopener">https://www.linkedin.com/legal/privacy-policy</a></p>

  <h4>Matomo (vormals: PIWIK)</h4>
  <p>In unserem Internetauftritt setzen wir Matomo (ehemals: „PIWIK“) ein. Hierbei handelt es sich um eine Open-Source-Software, mit der wir die Benutzung unseres Internetauftritts analysieren können. Hierbei werden Ihre IP-Adresse, die Website(s) unseres Internetauftritts, die Sie besuchen, die Website, von der aus Sie auf unseren Internetauftritt gewechselt haben (Referrer URL), Ihre Verweildauer auf unserem Internetauftritt sowie die Häufigkeit des Aufrufs einer unserer Websites verarbeitet.</p>
  <p>Zur Erfassung dieser Daten speichert Matomo über Ihren Internet-Browser ein Cookie auf Ihrem Endgerät. Dieses Cookie ist eine Woche lang gültig.</p>
  <p>Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Analyse und Optimierung unseres Internetauftritts.</p>
  <p>Allerdings nutzen wir Matomo mit der Anonymisierungsfunktion „Automatically Anonymize Visitor IPs“. Diese Anonymisierungsfunktion kürzt Ihre IP-Adresse um zwei Bytes, sodass eine Zuordnung zu Ihnen bzw. zu dem von Ihnen genutzten Internetanschluss unmöglich ist.</p>
  <p>Falls Sie mit dieser Verarbeitung nicht einverstanden sind, haben Sie die Möglichkeit, die Speicherung des Cookies durch eine Einstellung in Ihrem Internet-Browsers zu verhindern. Nähere Informationen hierzu finden Sie vorstehend unter „Cookies“.</p>
  <p>wird über Ihren Internet-Browser ein Cookie auf Ihrem Endgerät gespeichert, das die weitere Analyse verhindert. Bitte beachten Sie aber, dass Sie den obigen Link erneut betätigen müssen, sofern Sie die auf Ihrem Endgerät gespeicherten Cookies löschen.</p>

  <h4>Google-Maps</h4>
  <p>In unserem Internetauftritt setzen wir Google Maps zur Darstellung unseres Standorts sowie zur Erstellung einer Anfahrtsbeschreibung ein. Es handelt sich hierbei um einen Dienst der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, nachfolgend nur „Google“ genannt.</p>
  <p>Um die Darstellung bestimmter Schriften in unserem Internetauftritt zu ermöglichen, wird bei Aufruf unseres Internetauftritts eine Verbindung zu dem Google-Server in den USA aufgebaut.</p>
  <p>Sofern Sie die in unseren Internetauftritt eingebundene Komponente Google Maps aufrufen, speichert Google über Ihren Internet-Browser ein Cookie auf Ihrem Endgerät. Um unseren Standort anzuzeigen und eine Anfahrtsbeschreibung zu erstellen, werden Ihre Nutzereinstellungen und -daten verarbeitet. Hierbei können wir nicht ausschließen, dass Google Server in den USA einsetzt.</p>
  <p>Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Optimierung der Funktionalität unseres Internetauftritts.</p>
  <p>Durch die so hergestellte Verbindung zu Google kann Google ermitteln, von welcher Website Ihre Anfrage gesendet worden ist und an welche IP-Adresse die Anfahrtsbeschreibung zu übermitteln ist.</p>
  <p>Sofern Sie mit dieser Verarbeitung nicht einverstanden sind, haben Sie die Möglichkeit, die Installation der Cookies durch die entsprechenden Einstellungen in Ihrem Internet-Browser zu verhindern. Einzelheiten hierzu finden Sie vorstehend unter dem Punkt „Cookies“.</p>
  <p>Zudem erfolgt die Nutzung von Google Maps sowie der über Google Maps erlangten Informationen nach den <a href="http://www.google.de/accounts/TOS" target="_blank" rel="noopener">Google-Nutzungsbedingungen</a>&nbsp;<a href="https://policies.google.com/terms?gl=DE&amp;hl=de" target="_blank" rel="noopener">https://policies.google.com/terms?gl=DE&amp;hl=de</a> und den <a href="https://www.google.com/intl/de_de/help/terms_maps.html" target="_blank" rel="noopener">Geschäftsbedingungen für Google Maps</a> https://www.google.com/intl/de_de/help/terms_maps.html.</p>
  <p>Überdies bietet Google unter</p>
  <p><a href="https://adssettings.google.com/authenticated" target="_blank" rel="noopener">https://adssettings.google.com/authenticated</a></p>
  <p><a href="https://policies.google.com/privacy" target="_blank" rel="noopener">https://policies.google.com/privacy</a></p>
  <p>weitergehende Informationen an.</p>

  <h4>Google reCAPTCHA</h4>
  <p>In unserem Internetauftritt setzen wir Google reCAPTCHA zur Überprüfung und Vermeidung von Interaktionen auf unserer Internetseite durch automatisierte Zugriffe, bspw. durch sog. Bots, ein. Es handelt sich hierbei um einen Dienst der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, nachfolgend nur „Google“ genannt.</p>
  <p>Durch diesen Dienst kann Google ermitteln, von welcher Webseite eine Anfrage gesendet wird sowie von welcher IP-Adresse aus Sie die sog. reCAPTCHA-Eingabebox verwenden. Neben Ihrer IP-Adresse werden womöglich noch weitere Informationen durch Google erfasst, die für das Angebot und die Gewährleistung dieses Dienstes notwendig sind.&nbsp;&nbsp;&nbsp;</p>
  <p>Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Sicherheit unseres Internetauftritts sowie in der Abwehr unerwünschter, automatisierter Zugriffe in Form von Spam o.ä..</p>
  <p>Google bietet unter</p>
  <p><a href="https://policies.google.com/privacy" target="_blank" rel="noopener">https://policies.google.com/privacy</a></p>
  <p>weitergehende Informationen zu dem allgemeinen Umgang mit Ihren Nutzerdaten an.</p>

  <h4>Google Fonts</h4>
  <p>In unserem Internetauftritt setzen wir Google Fonts zur Darstellung externer Schriftarten ein. Es handelt sich hierbei um einen Dienst der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, nachfolgend nur „Google“ genannt.</p>
  <p>Um die Darstellung bestimmter Schriften in unserem Internetauftritt zu ermöglichen, wird bei Aufruf unseres Internetauftritts eine Verbindung zu dem Google-Server in den USA aufgebaut.</p>
  <p>Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Optimierung und dem wirtschaftlichen Betrieb unseres Internetauftritts.</p>
  <p>Durch die bei Aufruf unseres Internetauftritts hergestellte Verbindung zu Google kann Google ermitteln, von welcher Website Ihre Anfrage gesendet worden ist und an welche IP-Adresse die Darstellung der Schrift zu übermitteln ist.</p>
  <p>Google bietet unter</p>
  <p><a href="https://adssettings.google.com/authenticated" target="_blank" rel="noopener">https://adssettings.google.com/authenticated</a></p>
  <p><a href="https://policies.google.com/privacy" target="_blank" rel="noopener">https://policies.google.com/privacy</a></p>
  <p>weitere Informationen an und zwar insbesondere zu den Möglichkeiten der Unterbindung der Datennutzung.</p>

  <h4>FontAwesome</h4>
  <p>Um Schriften und visuelle Elemente unseres Internetauftritts darstellen zu können, setzen wir die externen Schriftarten von FontAwesome ein. Bei FontAwesome handelt es sich um einen Dienst der</p>
  <p>Fonticons Inc., 6 Porter Road, Apartment 3R, Cambridge, MA 02140, USA, nachfolgend nur „FontAwesome“ genannt.</p>
  <p>Bei dem Aufruf unseres Internetauftritts wird eine Verbindung zum Server von FontAwesome in den USA aufgebaut, um so die Darstellung von Schriften und visuellen Elemente zu ermöglichen und zu aktualisieren.</p>
  <p>Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Optimierung und dem wirtschaftlichen Betrieb unseres Internetauftritts.</p>
  <p>Durch die bei Aufruf unseres Internetauftritts hergestellte Verbindung zum Server von FontAwesome kann FontAwesome ermitteln, von welcher Website Ihre Anfrage gesendet worden ist und an welche IP-Adresse die Darstellung der Schrift zu übermitteln ist.</p>
  <p>FontAwesome bietet unter</p>
  <p><a href="https://fontawesome.com/privacy" target="_blank" rel="noopener">https://fontawesome.com/privacy</a></p>
  <p>weitere Informationen an und zwar insbesondere zu den Möglichkeiten der Unterbindung der Datennutzung.</p>

  <h4>CloudFlare</h4>
  <p>Zur Absicherung unseres Internetauftritts sowie zur Optimierung der Ladezeiten setzen wir den Dienst CloudFlare als sog. CDN (Content-Delivery-Network) ein. Es handelt sich hierbei um einen Dienst der Cloudflare Inc., 101 Townsend Street, San Francisco, California 94107, USA, nachfolgend nur „CloudFlare“ genannt.</p>
  <p>Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in dem sicheren Betrieb unseres Internetauftritts sowie in dessen Optimierung.</p>
  <p>Sofern Sie unseren Internetauftritt aufrufen, werden Ihre Anfragen über den Server von CloudFlare geleitet. Hierbei werden statistische Zugriffsdaten über den Besuch unseres Internetauftritts erhoben und durch CloudFlare ein Cookie über Ihren Internet-Browser auf Ihrem Endgerät gespeichert. Zu den Zugriffsdaten zählen</p>
  <p>- Ihre IP-Adresse,</p>
  <p>- die von Ihnen aufgerufene(n) Internetseite(n) unseres Internetauftritts,</p>
  <p>- Typ und Version des von Ihnen genutzten Internet - Browsers,</p>
  <p>- das von Ihnen genutzte Betriebssystem,</p>
  <p>- die Internetseite, von der sie auf unseren Internetauftritt gewechselt haben (Referrer- URL),</p>
  <p>- Ihre Verweildauer auf unserem Internetauftritt und&nbsp;</p>
  <p>- die Häufigkeit des Aufrufs unserer Internetseiten.</p>
  <p>Die Daten werden durch CloudFlare zum Zwecke statistischer Auswertungen der Zugriffe sowie zur Sicherheit und Optimierung des Angebots verwendet.</p>
  <p>Sofern Sie mit dieser Verarbeitung nicht einverstanden sind, haben Sie die Möglichkeit, die Installation der Cookies durch die entsprechenden Einstellungen in Ihrem Internet-Browser zu verhindern. Einzelheiten hierzu finden Sie vorstehend unter dem Punkt „Cookies“.</p>
  <p>CloudFlare bietet unter</p>
  <p><a href="https://www.cloudflare.com/privacypolicy/" target="_blank" rel="noopener">https://www.cloudflare.com/privacypolicy/</a></p>
  <p>weitere Informationen zur Erhebung und Nutzung der Daten sowie zu Ihren Rechten und Möglichkeiten zum Schutz Ihrer Privatsphäre an.</p>

  <h4>BootstrapCDN</h4>
  <p>Zur Optimierung der Abrufgeschwindigkeit, der Gestaltung und Darstellung der Inhalte unseres Internetauftritts auf unterschiedlichen Endgeräten setzen wir in unserem Internetauftritt den Dienst BootstrapCDN, ein Content Delivery Network („CDN“), ein. Hierbei handelt es sich um einen Dienst der StackPath, LLC, 2021 McKinney Ave. Suite 1100, Dallas, TX 75201, USA, nachfolgend nur „BootstrapCDN“ genannt.</p>
  <p>Um die Inhalte unseres Internetauftritts schnell ausliefern zu können, greift der Dienst auf sog. JavaScript-Bibliotheken zurück. Dabei werden entsprechende Dateien von einem Server von BootstrapCDN geladen, der Ihre IP-Adresse erfasst.</p>
  <p>Zudem bietet BootstrapCDN unter</p>
  <p><a href="https://www.stackpath.com/legal/master-service-agreement/#privacy" target="_blank" rel="noopener">https://www.stackpath.com/legal/master-service-agreement/#privacy</a> &nbsp;&nbsp;</p>
  <p>weitergehende Datenschutzinformationen an.</p>
  <p>Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Beschleunigung der Ladezeiten unseres Internetauftritts sowie in dessen Optimierung.</p>
  <p>Um die Ausführung des Java-Script-Codes von BootstrapCDN insgesamt zu verhindern, können Sie einen sog. Java-Script-Blocker installieren, wie bspw. noscript.net oder ghostery.com. Sollten Sie die Ausführung des Java-Script-Codes verhindern oder einschränken, kann dies aus technischen Gründen allerdings dazu führen, dass womöglich nicht sämtliche Inhalte und Funktionen unseres Internetauftritts verfügbar sind.</p>

  <h4>jQuery CDN</h4>
  <p>Zur Optimierung der Abrufgeschwindigkeit, der Gestaltung und Darstellung der Inhalte unseres Internetauftritts auf unterschiedlichen Endgeräten setzen wir in unserem Internetauftritt den Dienst</p>
  <p>jQuery CDN, ein Content Delivery Network („CDN“), ein. Hierbei handelt es sich um einen Dienst der jQuery Foundation, nachfolgend nur „jQuery“ genannt. jQuery wird für die JS Foundation über das StackPath CDN verteilt.</p>
  <p>Um die Inhalte unseres Internetauftritts schnell ausliefern zu können, greift der Dienst auf sog. JavaScript-Bibliotheken zurück. Dabei werden entsprechende Dateien von dem CDN-Server geladen, soweit sie nicht schon durch einen Besuch eines anderen Internetauftritts in Ihrem Browser-Cache vorhanden sind. Im Falle der Verbindung zu dem CDN-Server wird Ihre IP-Adresse erfasst. Dabei kann nicht ausgeschlossen werden, dass eine Verbindung zu einem Server außerhalb der EU erfolgt.</p>
  <p>Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Beschleunigung der Ladezeiten unseres Internetauftritts sowie in dessen Optimierung.</p>
  <p>Um die Ausführung des Java-Script-Codes von jQuery insgesamt zu verhindern, können Sie einen sog. Java-Script-Blocker installieren, wie bspw. noscript.net oder ghostery.com. Sollten Sie die Ausführung des Java-Script-Codes verhindern oder einschränken, kann dies aus technischen Gründen allerdings dazu führen, dass womöglich nicht sämtliche Inhalte und Funktionen unseres Internetauftritts verfügbar sind.</p>

  <h4>affilinet-Tracking-Cookies</h4>
  <p>In unserem Internetauftritt bewerben wir auch Angebote und Leistungen Dritter. Sofern Sie aufgrund unserer Werbung für diese Drittangebote einen Vertrag mit dem Drittanbieter schließen, erhalten wir hierfür eine Provision.</p>
  <p>Um die erfolgreiche Vermittlung korrekt erfassen zu können, setzen wir das sog. affilinet-Tracking-Cookie ein. Dieses Cookie speichert aber keine Ihrer personenbezogenen Daten. Es wird lediglich unsere Identifikationsnummer als vermittelnder Anbieter sowie die Ordnungsnummer des von Ihnen angeklickten Werbemittels (bspw. eines Banners oder eines Textlinks) erfasst. Diese Informationenbenötigen wir zum Zwecke der Zahlungsabwicklung bzw. Auszahlung unserer Provisionen.</p>
  <p>Falls Sie mit dieser Verarbeitung nicht einverstanden sind, haben Sie die Möglichkeit, die Speicherung der Cookies durch eine Einstellung in Ihrem Internet-Browsers zu verhindern. Nähere Informationen hierzu finden Sie vorstehend unter „Cookies“.</p>

  <p><a href="https://www.ratgeberrecht.eu/leistungen/muster-datenschutzerklaerung.html" target="_blank" rel="noopener">Muster-Datenschutzerklärung</a> der <a href="https://www.ratgeberrecht.eu/datenschutz/datenschutzerklaerung-generator-dsgvo.html" target="_blank" rel="noopener">Anwaltskanzlei Weiß &amp; Partner</a></p>

</div>`,
    course1: {
      description: `##  Seminar content:  DataCore Swarm DCIE Training
 <br>

 ### Swarm Administrator Basics
 - Replication Feeds
 - SCS Backup & Restore
 -  Scale up and Scale Down Storage Nodes
 - Swarm Recovery and Protection Erasure Coding
 - Swarm Object locking

 <br>

### Swarm Advanced
- Components Descriptions
- Storage Cluster and Architecture
- Hardware sizing
- Networking overview
- Swarm Cluster service
- SCS Configuration & Installation
- Swarm User Interface and Storage Management
- Content Gateway and Gateway with S3
- Planning Gateway to LDAP / AD / PAM
- Object versioning
-  Swarm Elastic search
- Feeds
- Prometheus
- Alerting
- Cluster & Veeam Backup (Video only)
- DataCore Support

 <br>

### Swarm DCIE test with certification
**Prerequisites:** DCSA DataCore Certified Solution Architect ***Recommended*** <br>
**Certification:** This course is part of the DataCore Certified Implementation Engineer (DCIE) certification.  <br>
**Note:** For this seminar you need an internet capable notebook.  <br>

 <br>

***We ask for your understanding that courses with less than 3 registrations cannot take place.***
In this case, you will receive alternative dates. <br>
This event takes place in cooperation with DataCore. With your registration you agree to the transfer of your contact data to the manufacturer.

**Seminar fee:** ***€ 2.700,00 per participant***`,
      schedule: [
        { day: "Monday", time: "12:00 - 17:00" },
        { day: "Tuesday", time: "09:00 - 17:00" },
        { day: "Wednesday", time: "09:00 - 17:00" },
        { day: "Thursday", time: "09:00 - 17:00" },
        { day: "Friday", time: "09:00 - 12:00" }
      ],
      course: "SWARM DCIE DataCore Certified Implementation Engineer",
      date: "04.11.24 - 08.11.2024",
      duration: "4 days with Lunch",
      location: "StorTrec AG, Nordring 53-55, 63843 Niedernberg (2. Floor)",
      icon: "@/assets/DataCore.svg",
      language: "German"
    },
    course2: {
      description: `##  Seminar content:  DataCore Swarm DCIE Training
 <br>

 ### Swarm Administrator Basics
 - Replication Feeds
 - SCS Backup & Restore
 -  Scale up and Scale Down Storage Nodes
 - Swarm Recovery and Protection Erasure Coding
 - Swarm Object locking

 <br>

### Swarm Advanced
- Components Descriptions
- Storage Cluster and Architecture
- Hardware sizing
- Networking overview
- Swarm Cluster service
- SCS Configuration & Installation
- Swarm User Interface and Storage Management
- Content Gateway and Gateway with S3
- Planning Gateway to LDAP / AD / PAM
- Object versioning
-  Swarm Elastic search
- Feeds
- Prometheus
- Alerting
- Cluster & Veeam Backup (Video only)
- DataCore Support

 <br>

### Swarm DCIE test with certification
**Prerequisites:** DCSA DataCore Certified Solution Architect ***Recommended*** <br>
**Certification:** This course is part of the DataCore Certified Implementation Engineer (DCIE) certification.  <br>
**Note:** For this seminar you need an internet capable notebook.  <br>

 <br>

***We ask for your understanding that courses with less than 3 registrations cannot take place.***
In this case, you will receive alternative dates. <br>
This event takes place in cooperation with DataCore. With your registration you agree to the transfer of your contact data to the manufacturer.

**Seminar fee:** ***€ 2.700,00 per participant***`,
      schedule: [
        { day: "Monday", time: "12:00 - 17:00" },
        { day: "Tuesday", time: "09:00 - 17:00" },
        { day: "Wednesday", time: "09:00 - 17:00" },
        { day: "Thursday", time: "09:00 - 17:00" },
        { day: "Friday", time: "09:00 - 12:00" }
      ],
      course: "SWARM DCIE DataCore Certified Implementation Engineer",
      date: "03.02.25 - 07.02.2025",
      duration: "4 days with Lunch",
      location: "StorTrec AG, Nordring 53-55, 63843 Niedernberg (2. Floor)",
      icon: "@/assets/DataCore.svg",
      language: "German"
    }
  })
}
</script>
